import React, { Component } from 'react';
import paymentB from './PaymentB.module.css';

class Payment extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 销售流程 {'>'} 订单环节</div>
         
          <div>
              <div className={paymentB.intro_1}>查看用户当前未收款、已收款的明细记录</div>
              <div className={paymentB.intro_1}>一、款项环节</div>
              <div className={paymentB.intro_1}>1、采购商用户付款后,供应商用户可在未收款页中查看并进行收款操作。</div>
              <div className={paymentB.intro}>2、每条未收款记录的操作中点击收款，根据票据单号或者转账凭证，核对银行实际转账记录金额与采购商付款金额一致后，点击确认收款，即可完成收款操作。</div>
              <img src={require('../../public/sell/16.png')}   alt="log-in" width="780px" />
              <img src={require('../../public/sell/17.png')}   alt="log-in" width="780px" />
             
          </div>
        </div>
      );
    }
  }
  export default Payment;