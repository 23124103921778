import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Layout, Menu } from 'antd';
import Procurement from './Procurement';
import Sell from './Sell';
const { Header, Content, Footer } = Layout;
class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            key: "1"
        }
    }

    state = {
        key: ''
    };

    handleClick = e => {
        this.setState({
            key: e.key,
        });
        console.log(e)
    };
    render() {

        let nowYear = new Date().getFullYear()
        var content = null
        if (this.state.key === "1") {
            content = <Procurement />
        } else {
            content = <Sell />
        }
        return (
            <Layout>
                <div className="headblue">
                    <div className="logo-warp">
                        <a target='blank' href="https://www.ckmro.com/" ><img src={require('./public/logo.svg')} alt="logo" width="400px" /></a>
                    </div>
                    <div className="dot">·</div>
                    <div className="help">帮助中心</div>
                </div>
                <Header className="header" >
                    <Menu

                        theme="light"
                        mode="horizontal"
                        defaultSelectedKeys={['1']}
                        style={{ lineHeight: '64px', margin: "0 auto " }}
                        onClick={this.handleClick}
                        selectedKeys={[this.state.key]}
                    >
                        <Menu.Item className="headMenu1" key="1">采购流程</Menu.Item>
                        <Menu.Item className="headMenu2" key="2">销售流程</Menu.Item>
                    </Menu>
                </Header>
                <Content style={{ padding: '0 50px', width: '1000px' }}>
                    {content}
                </Content>
                <Footer className="footer_1" style={{ textAlign: 'center' }}><a target='blank' href='http://beian.miit.gov.cn'>闽ICP备17001753号</a> 营业执照 法律声明</Footer>
                <Footer className="footer_2" style={{ textAlign: 'center' }}>Copyright ©2016-{nowYear} 福建采控网络科技有限公司 版权所有</Footer>
            </Layout>

        );
    }
}

ReactDOM.render(<Index />, document.getElementById('root'))
