import React, { Component } from 'react';
import paperB from './PaperB.module.css';

class PaperB extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 销售流程 {'>'} 订单环节</div>
         
          <div>
              <div className={paperB.intro_1}>查看用户当前交易的票据,包括未开票和已开票的记录</div>
              <div className={paperB.intro_1}>一、票据环节</div>
              <div className={paperB.intro}>1、未开票页展示供应商用户销售交易中,还未开票的发票项。选中需要开票的项,点击"开票"按钮,选择物流公司并输入物流单号，或输入票据号码,或上传票据凭证,点击确认开票,即可完成开票操作。供应商用户可对同一采购商用户不同订单的开票项进行跨订单统一开票。</div>
              <img src={require('../../public/sell/22.png')}   alt="log-in" width="780px" />
              <img src={require('../../public/sell/23.png')}   alt="log-in" width="780px" />
              <div className={paperB.intro_1}>2.已开票页展示用户销售交易中,供应商已开票的记录。</div> 
          </div>
        </div>
      );
    }
  }
  export default PaperB;