import React, { Component } from 'react';
import enter from './EnterInquiry.module.css';

class EnterInquiry extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 采购流程 {'>'} 进入询价</div>
         
          <div>
            <div className={enter.intro_1}>简单两步，完成询价</div>
              <div className={enter.intro}>一、导入询价/手动录入询价</div>
              <img src={require('../../public/procurement/07.png')}   alt="log-in" width="780px" />
              <img src={require('../../public/procurement/08.png')}   alt="log-in" width="780px" />
           
              <div className={enter.intro_1}>二、选择询价渠道</div>
              <div className={enter.intro_1}>1、可选择采控网的供应商进行全网询价，也可将用户的线下供应商渠道添加到 我要采购{'>'}渠道管理{'>'}我的渠道 中进行指定询价。</div>
              <div className={enter.intro}>2、默认询价渠道为全网询价(在采控网注册认证的所有品牌正规代理供应商)。</div>
                <img src={require('../../public/procurement/09.png')}   alt="log-in" width="780px" />
          </div>
        </div>
      );
    }
  }
  export default EnterInquiry;