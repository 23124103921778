import { Layout, Menu } from 'antd';
import React from 'react';
import Login from './components/Login/Login'
import Auth from './components/Auth/Auth'
import PurchaseSet from './components/PurchaseSet/PurchaseSet'
import EnterInquiry from './components/EnterInquiry/EnterInquiry'
import ViewOffer from './components/ViewOffer/ViewOffer'
import OrderTransactions from './components/OrderTransactions/OrderTransactions'
import PurchaseOrder from './components/PurchaseOrder/PurchaseOrder'
import Contract from './components/Contract/Contract'
import Payment from './components/Payment/Payment'
import Goods from './components/Goods/Goods'
import Paper from './components/Paper/Paper'
import './Procurement.css'
const { Content, Sider } = Layout;

class Procurement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      current: "login"
    }
  }

  state = {
    current: ''
  };

  handleClick = e => {
    this.setState({
      current: e.key,
    });
    console.log(this.state.current)
  };


  render() {
    var element = ''
    if (this.state.current === 'login') {
      element = <Login />
    }
    else if (this.state.current === 'auth') {
      element = <Auth />
    }
    else if (this.state.current === 'purchaseSet_1' || this.state.current === 'purchaseSet') {
      element = <PurchaseSet />
    }
    else if (this.state.current === 'enterInquiry') {
      element = <EnterInquiry />
    }
    else if (this.state.current === 'viewOffer') {
      element = <ViewOffer />
    }
    else if (this.state.current === 'orderTransactions') {
      element = <OrderTransactions />
    }
    else if (this.state.current === 'purchaseOrder') {
      element = <PurchaseOrder />
    }
    else if (this.state.current === 'contract' || this.state.current === 'contract_1') {
      element = <Contract />
    }
    else if (this.state.current === 'payment') {
      element = <Payment />
    }
    else if (this.state.current === 'goods') {
      element = <Goods />
    }
    else if (this.state.current === 'paper') {
      element = <Paper />
    }
    return (


      <Layout style={{ padding: '24px 0' }}>
        <Sider width={200} style={{ background: '#fff' }}>

          <Menu
            mode="inline"
            defaultSelectedKeys={['login']}
            // defaultOpenKeys={['sub1']}
            style={{ height: '100%' }}
            onClick={this.handleClick}
            selectedKeys={[this.state.current]}
          >
            <div className="sider-guide">帮助中心</div>

            <Menu.Item key="login" className="proMenuItem">1、登录注册</Menu.Item>

            <Menu.Item key="auth" className="proMenuItem">2、认证问题</Menu.Item>
            {/* <Menu.Item key="auth_1">&nbsp;&nbsp;&nbsp;&nbsp;2.1 引导认证</Menu.Item>
              <Menu.Item key="auth_2">&nbsp;&nbsp;&nbsp;&nbsp;2.2 完善认证</Menu.Item> */}


            <Menu.Item key="purchaseSet" className="proMenuItem">3、采购流程</Menu.Item>
            <Menu.Item key="purchaseSet_1" className="proMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;3.1 采购设置</Menu.Item>
            {/* <Menu.Item  key="purchaseSet_2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;交易条件</Menu.Item>
               <Menu.Item  key="purchaseSet_3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;交易地址</Menu.Item> */}


            <Menu.Item key="enterInquiry" className="proMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;3.2 进入询价</Menu.Item>
            {/* <Menu.Item key="enterInquiry_1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;编辑询价产品</Menu.Item>
               <Menu.Item key="enterInquiry_2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;选择询价渠道</Menu.Item>
               <Menu.Item key="enterInquiry_3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;发送询价</Menu.Item> */}

            {/* <SubMenu key="enterInquiry" title="3.2 进入询价">
                <Menu.Item key="enterInquiry">编辑询价产品</Menu.Item>
                <Menu.Item key="">选择询价渠道</Menu.Item>
                <Menu.Item key="8">发送询价</Menu.Item>
          </SubMenu> */}
            <Menu.Item key="viewOffer" className="proMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;3.3 查看报价</Menu.Item>
            <Menu.Item key="orderTransactions" className="proMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;3.4 下单交易</Menu.Item>
            <Menu.Item key="purchaseOrder" className="proMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;3.5 采购订单</Menu.Item>
            {/* <SubMenu key="sub5" title="3.6 订单环节">
              <Menu.Item key="12">合同环节</Menu.Item>
              <Menu.Item key="13">款项环节</Menu.Item>
              <Menu.Item key="14">货物环节</Menu.Item>
              <Menu.Item key="15">票据环节</Menu.Item>
          </SubMenu>
   */}
            <Menu.Item key="contract" className="proMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;3.6 订单环节</Menu.Item>
            <Menu.Item key="contract_1" className="proMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;合同环节</Menu.Item>
            <Menu.Item key="payment" className="proMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;款项环节</Menu.Item>
            <Menu.Item key="goods" className="proMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;货物环节</Menu.Item>
            <Menu.Item key="paper" className="proMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;票据环节</Menu.Item>


          </Menu>
        </Sider>
        <Content style={{ padding: '0px', minHeight: 280, width: 850, background: '#fff' }}>
          {element}
        </Content>
      </Layout>

    )
  }
}

export default Procurement

