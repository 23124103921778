import React, { Component } from 'react';
import payment from './Payment.module.css';

class Payment extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 采购流程 {'>'} 订单环节</div>
         
          <div>
              <div className={payment.intro_1}>查看用户当前未付款、已付款的明细记录</div>
              <div className={payment.intro_1}>一、款项环节</div>
              <div className={payment.intro_1}>1、选择所要付款的明细，点击批量付款，输入银行转账的票据单号或上传银行转账的票据凭证后确认付款，即可完成付款操作。</div>
              <div className={payment.intro_1}>2、可对同一供应商的不同订单明细进行跨订单批量付款。</div>
              <div className={payment.intro}>3、与平台交易：付款方式以平台对每个用户设置的付款方式为准，可与业务人员沟通申请；<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;与好友交易：付款方式以好友供应商报价单中设置的付款方式为准。</div>
              <img src={require('../../public/procurement/18.png')}   alt="log-in" width="780px" />
              <img src={require('../../public/procurement/19.png')}   alt="log-in" width="780px" />
             
          </div>
        </div>
      );
    }
  }
  export default Payment;