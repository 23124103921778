import React, { Component } from 'react';
import sellset from './SellSet.module.css';

class SellSet extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 销售流程 {'>'} 销售设置</div>
          <div>
            <div className={sellset.intro}>首次使用,建议先进行销售设置,后续销售报价无需再进行设置。</div>
            <div className={sellset.intro}>一、交易条件设置</div>
              <img src={require('../../public/sell/03.png')}   alt="log-in" width="780px" />
                <div className={sellset.titleAndDesc}><div className={sellset.title}>报价税率：</div><div className={sellset.desc}>默认报价明细所含的税率；</div></div>
                <div className={sellset.titleAndDesc}><div className={sellset.title}>付款方式：</div><div className={sellset.desc}>设置销售产品的付款方式；</div></div>
                <div className={sellset.titleAndDesc}><div className={sellset.title}>发票类型：</div><div className={sellset.desc}>可选增值发票或普通发票；</div></div>
              <div className={sellset.intro}>二、交易地址设置</div>
                <img src={require('../../public/sell/04.png')}   alt="log-in" width="780px" />
                <div className={sellset.titleAndDesc}><div className={sellset.title}>地址信息包括：</div><div className={sellset.desc}>姓名、手机号码、收货地址。</div></div>
                <div className={sellset.titleAndDesc}><div className={sellset.title}>指定收件方式：</div><div className={sellset.desc}>限定重量并指定物流快递。</div></div>
                <div className={sellset.titleAndDesc}><div className={sellset.title}>默认：</div><div className={sellset.desc}>可设置地址为默认收货地址或默认收票地址进行使用。</div></div>
          </div>
        </div>
      );
    }
  }
  export default SellSet;