import React, { Component } from 'react';
import auth from './Auth.module.css';

class Auth extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 认证问题</div>
         
          <div>
              <div className={auth.intro}>一、工作面板引导认证</div>
          
                <img src={require('../../public/procurement/02.png')}   alt="log-in" width="780px" />
           
              <div className={auth.intro_1}>二、完善信息：点击右上角用户状态后的“完善信息”按钮进行认证。</div>
           
                <img src={require('../../public/procurement/03.png')}   alt="log-in" width="780px" />
                <img src={require('../../public/procurement/04.png')}   alt="log-in" width="780px" />
             
          
              <div className="cgs">提交认证信息，待平台审核！</div>
          </div>
        </div>
      );
    }
  }
  export default Auth;