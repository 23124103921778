import React, { Component } from 'react';
import good from './Goods.module.css';

class Goods extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 采购流程 {'>'} 订单环节</div>
         
          <div>
              <div className={good.intro_1}>查看用户当前交易的明细，包括未发货、已收货的明细</div>
              <div className={good.intro_1}>一、货物环节</div>
              <div className={good.intro_1}>1、未发货页展示用户采购交易中,供应商还未发货的交易产品明细。</div>
              <div className={good.intro_1}>2、未收货页展示用户采购交易中,供应商已发货但采购商用户还未确认收货的记录。记录中可包含同一供应商发来的不同订单的交易产品明细。点击每条收货记录的"收货”操作，核对物流记录中的货物明细一致后，确认收货。即可完成收货操作。</div>
              <div className={good.intro}>3、已收货页展示用户采购交易中,已经确认收货的记录。</div>
              <img src={require('../../public/procurement/20.png')}   alt="log-in" width="780px" />
              <img src={require('../../public/procurement/21.png')}   alt="log-in" width="780px" />
             
          </div>
        </div>
      );
    }
  }
  export default Goods;