import React, { Component } from 'react';
import uploadCert from './UploadCert.module.css';

class UploadCert extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 销售流程 {'>'} 上传代理证书</div>
          <div>
              <div className={uploadCert.intro_1}>供应商用户需提供有效的品牌代理证书,经系统审核通过后,方可收到平台推送的相关品牌的询价信息,并进行报价。</div>
              <div className={uploadCert.intro_1}>一、点击上传代理证书打开 “新增代理品牌” 窗口,输入或选择所要代理的品牌并上传代理证书,点击提交，等待平台审核通过后，即可对该品牌的明细进行报价。</div>
              <div className={uploadCert.intro}>二、好友交易,供应商无需上传代理品牌证书,所有品牌均可进行报价。</div>
              <img src={require('../../public/sell/01.png')}   alt="log-in" width="780px" />
              <img src={require('../../public/sell/02.png')}   alt="log-in" width="780px" />
             
          </div>
        </div>
      );
    }
  }
  export default UploadCert;