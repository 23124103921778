import React, { Component } from 'react';
import contractB from'./ContractB.module.css';

class ContractB extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 销售流程 {'>'} 订单环节</div>
         
          <div>
              <div className={contractB.intro_1}>供应商用户的销售订单，由供应商用户自身确认后，即可进入订单环节进行交易。包括四个环节：合同、款项、货物、票据。</div>
              <div className={contractB.intro_1}>一、合同环节</div>
              <div className={contractB.intro_1}>1、选择模板进行——交易双方的任意一方均可先选择未进行签字盖章的合同模板(可选择采控网提供的模板，或上传用户本地的自定义模板),并由交易双方的任意一方根据合同模板进行下载、打印、签字、盖章，再上传合同文件等待对方确认，另一方进行合同文件确认后，同样的步骤回传合同文件等待对方确认。双方均确认合同文件后，合同环节完成。</div>
              <div className={contractB.intro}>2、跳过模板进行——交易双方的任意一方均可跳过模板环节，直接上传已签字、盖章的合同文件等待对方确认，另一方进行合同文件确认后，同样的步骤回传合同文件等待对方确认。双方均确认合同文件后，合同环节完成。</div>
              <img src={require('../../public/sell/13.png')}   alt="log-in" width="780px" />
              <img src={require('../../public/sell/14.png')}   alt="log-in" width="780px" />
              <img src={require('../../public/sell/15.png')}   alt="log-in" width="780px" />
          </div>
        </div>
      );
    }
  }
  export default ContractB;