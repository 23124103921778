
import React, { Component } from 'react';
import  confirmOffer from './ConfirmOffer.module.css';

class ConfirmOffer extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 销售流程 {'>'} 确认订单</div>
         
          <div>
              <div className={confirmOffer.intro_1}>供应商用户收到销售交易订单时,点击订单号进入“订单详情页”, 核对信息无误后,点击“确认订单” , 完成订单确认操作。</div>
              <div className={confirmOffer.intro}>一、确认订单</div>
              <img src={require('../../public/sell/10.png')}   alt="log-in" width="780px" />
          </div>
        </div>
      );
    }
  }
  export default ConfirmOffer;