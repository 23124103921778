import React, { Component } from 'react';
import './Login.css';

class Login extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 登录注册</div>
          <div className="intro">简单3步，完成登录注册</div>
          <div>
              <div className="pic-warp">
                <img src={require('../../public/procurement/01.png')}   alt="log-in" />
              </div>
              <div className="point">
                <div>1、请输入正确的手机号码；</div>
                <div>2、设置登录密码；</div>
                <div>3、获取手机短信验证码并输入；</div>
              </div>
              <div className="cgs">恭喜您，采控网账号注册成功!</div>
          </div>
        </div>
      );
    }
  }
  export default Login;