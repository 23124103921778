import { Layout, Menu } from 'antd';
import React from 'react';
import Login from './components/Login/Login'
import Auth from './components/Auth/Auth'
import UploadCert from './components/UploadCert/UploadCert'
import SellSet from './components/SellSet/SellSet'
import CheckInquiry from './components/CheckInquiry/CheckInquiry'
import BeginToOffer from './components/BeginToOffer/BeginToOffer'
import ConfirmOffer from './components/ConfirmOffer/ConfirmOffer'
import SalesOrder from './components/SalesOrder/SalesOrder'
import ContractB  from './components/ContractB/ContractB'
import GoodsB  from './components/GoodsB/GoodsB'
import PaperB  from './components/PaperB/PaperB'
import PaymentB  from './components/PaymentB/PaymentB'
import  './Sell.css'
const { Content,  Sider } = Layout;

class Sell extends React.Component{
  constructor(props) {
    super(props)
    this.state= {
      current:"login"
    }
  }
  state = {
    current: ''
  };

  handleClick = e => {
    this.setState({
      current: e.key,
    });
    console.log(this.state.current)
  };
  

  render(){
    var element=''
    if(this.state.current==='login'){
      element=<Login />
    }
    else if(this.state.current==='auth'){
        element=<Auth />
      }
    else if(this.state.current==='uploadCert'||this.state.current==='uploadCert_1'){
        element=<UploadCert />
    }
    else if(this.state.current==='sellSet'){
        element=<SellSet />
    }
    else if(this.state.current==='checkInquiry'){
        element=<CheckInquiry />
    }
    else if(this.state.current==='beginToOffer'){
        element=<BeginToOffer />
    }
    else if(this.state.current==='confirmOffer'){
        element=<ConfirmOffer />
    }
    else if(this.state.current==='salesOrder'){
        element=<SalesOrder />
    }
    else if(this.state.current==='contractB'||this.state.current==='contractB_1'){
        element=<ContractB />
    }
    else if(this.state.current==='goodsB'){
        element=<GoodsB />
    }
    else if(this.state.current==='paperB'){
        element=<PaperB />
    }
    else if(this.state.current==='paymentB'){
        element=<PaymentB />
    }
    return(
        <Layout style={{ padding: '24px 0' }}>
          <Sider width={200} style={{ background: '#fff' }}>
         
            <Menu
              mode="inline"
              defaultSelectedKeys={['login']}
              // defaultOpenKeys={['sub1']}
              style={{ height: '100%' }}
              onClick={this.handleClick} 
              selectedKeys={[this.state.current]}
            >
               <div className="sider-guide">帮助中心</div>
           
               <Menu.Item key="login" className="sellMenuItem">1、登录注册</Menu.Item>
               <Menu.Item key="auth" className="sellMenuItem">2、认证问题</Menu.Item>
               {/* <Menu.Item key="auth_1">&nbsp;&nbsp;&nbsp;&nbsp;2.1 引导认证</Menu.Item>
               <Menu.Item key="auth_2">&nbsp;&nbsp;&nbsp;&nbsp;2.2 完善认证</Menu.Item> */}
               <Menu.Item key="uploadCert" className="sellMenuItem">3、销售流程</Menu.Item>
               <Menu.Item key="uploadCert_1" className="sellMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;3.1 上传代理证书</Menu.Item>
               <Menu.Item key="sellSet" className="sellMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;3.2 销售设置</Menu.Item>

                {/* <Menu.Item key="sellSet_1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;交易条件</Menu.Item>
                <Menu.Item key="sellSet_2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;交易地址</Menu.Item> */}
          
                    <Menu.Item key="checkInquiry" className="sellMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;3.3 查看询价</Menu.Item>
                    <Menu.Item key="beginToOffer" className="sellMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;3.4 开始报价</Menu.Item>
                    <Menu.Item key="confirmOffer" className="sellMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;3.5 确认订单</Menu.Item>
                    <Menu.Item key="salesOrder" className="sellMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;3.6 销售订单</Menu.Item>
                    <Menu.Item key="contractB" className="sellMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;3.7 订单环节</Menu.Item>
                    <Menu.Item key="contractB_1" className="sellMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;合同环节</Menu.Item>
                    <Menu.Item key="paymentB" className="sellMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;款项环节</Menu.Item>
                    <Menu.Item key="goodsB" className="sellMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;货物环节</Menu.Item>
                    <Menu.Item key="paperB" className="sellMenuItem">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;票据环节</Menu.Item>
                  
            </Menu>
          </Sider>
          <Content style={{ padding: '0px', minHeight: 280 ,width:850,background:'#fff'}}>
          {element}
          </Content>
        </Layout>
    )
  }
}

export default  Sell

