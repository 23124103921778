
import React, { Component } from 'react';
import salesOrder from './SalesOrder.module.css';

class SalesOrder extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 销售流程 {'>'} 销售订单</div>
         
          <div>
              <div className={salesOrder.intro_1}>供应商确认订单后,在我的订单下生成订单</div>
              <div className={salesOrder.intro}>一、在我的订单下查看销售订单状态</div>
              <img src={require('../../public/sell/11.png')}   alt="log-in" width="780px" />
          </div>
        </div>
      );
    }
  }
  export default SalesOrder;