import React, { Component } from 'react';
import checkInquiry from './CheckInquiry.module.css';

class CheckInquiry extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 销售流程 {'>'} 查看询价</div>
         
          <div>
              <div className={checkInquiry.intro_1}>代理品牌认证经过系统审核后,用户收到来自于平台的询价单,可通过两种方式进行查看。</div>
              <div className={checkInquiry.intro}>一、 明细报价</div>
              <img src={require('../../public/sell/05.png')}   alt="log-in" width="780px" />
              <div className={checkInquiry.intro}>二、整单目录,进入报价单详情页面查看</div>
              <img src={require('../../public/sell/06.png')}   alt="log-in" width="780px" />
              <div className="intro">注:报价时仅需填写单价和货期。报价数量默认同询价数量,可按实际销售数量进行修改。</div>
          </div>
        </div>
      );
    }
  }
  export default CheckInquiry;