import React, { Component } from 'react';
import orderTrans from './OrderTransactions.module.css';

class OrderTransactions extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 采购流程 {'>'} 下单交易</div>
         
          <div>
              <div className={orderTrans.intro_1}>询价发送成功后，系统将供应商的报价反馈给采购商用户</div>
              <div className={orderTrans.intro}>一、在整单目录页和询价明细页均可查看询价产品的报价</div>
              <img src={require('../../public/procurement/12.png')}   alt="log-in" width="780px" />
           
              <div className={orderTrans.intro}>二、选择明细，可根据中标进行下单</div>
                <img src={require('../../public/procurement/13.png')}   alt="log-in" width="780px" />
          </div>
        </div>
      );
    }
  }
  export default OrderTransactions;