import React, { Component } from 'react';
import purchaseorder from './PurchaseOrder.module.css';

class PurchaseOrder extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 采购流程 {'>'} 采购订单</div>
         
          <div>
              <div className={purchaseorder.intro_1}>采购商确认下单后，在我的订单下生成订单</div>
              <div className={purchaseorder.intro}>一、在我的订单下查看采购订单状态</div>
              <img src={require('../../public/procurement/14.png')}   alt="log-in" width="780px" />
          </div>
        </div>
      );
    }
  }
  export default PurchaseOrder;