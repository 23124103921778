import React, { Component } from 'react';
import paper from './Paper.module.css';

class Paper extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 采购流程 {'>'} 订单环节</div>
         
          <div>
              <div className={paper.intro_1}>查看用户当前交易的票据,包括未开票、未收票、已收票的记录</div>
              <div className={paper.intro_1}>一、票据环节</div>
              <div className={paper.intro_1}>1、未开票页展示用户采购交易中,供应商还未开票的发票项。</div>
              <div className={paper.intro_1}>2、未收票页展示用户采购交易中，供应商已开票但采购商用户还未确认收票的记录。记录中可包含同一供应商开具的不同订单的发票项。点击每条收票记录的“收票”操作,核对发票中的金额与收票界面中的金额一致后，确认收票。即可完成收票操作。</div>
              <div className={paper.intro}>3、已收票页展示用户采购交易中,已经确认收票的记录。</div>
              <img src={require('../../public/procurement/22.png')}   alt="log-in" width="780px" />
              <img src={require('../../public/procurement/23.png')}   alt="log-in" width="780px" />
             
          </div>
        </div>
      );
    }
  }
  export default Paper;