import React, { Component } from 'react';
import viewoffer from './ViewOffer.module.css';

class ViewOffer extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 采购流程 {'>'} 查看报价</div>
         
          <div>
              <div className={viewoffer.intro_1}>询价发送成功后，系统将供应商的报价反馈给采购商用户。</div>
              <div className={viewoffer.intro}>一、在整单目录页和询价明细页均可查看询价产品的报价</div>
              <img src={require('../../public/procurement/11.png')}   alt="log-in" width="780px" />
          </div>
        </div>
      );
    }
  }
  export default ViewOffer;