import React, { Component } from 'react';
import goodsB from './GoodsB.module.css';

class GoodsB extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 销售流程 {'>'} 订单环节</div>
         
          <div>
              <div className={goodsB.intro_1}>查看用户当前交易的明细，包括未备货、未发货、出库单、已发货的明细</div>
              <div className={goodsB.intro_1}>一、货物环节</div>
              <div className={goodsB.intro}>1、未备货页展示用户当前应备货但未完成备货的销售明细。选择所要备货的明细,点击“入库通知采购商”, 即可完成备货操作。</div>
              <img src={require('../../public/sell/18.png')}   alt="log-in" width="780px" />
              <div className={goodsB.intro}>2、未发货页展示用户当前应发货但未完成发货的销售明细，选择所要发货的明细，点击“发货” 并确认，即可完成生成出库单操作。供应商用户可批量对同一采购商用户的不同订单交易明细生成同一份出库单。</div>
              <img src={require('../../public/sell/19.png')}   alt="log-in" width="780px" />
              <div className={goodsB.intro}>3、出库单页展示用户已经确认好即将进行发货的出库单,点击出库单号,选择物流公司并输入物流单号或上传发货凭证,点击确认发货，发货成功</div>
              <img src={require('../../public/sell/20.png')}   alt="log-in" width="780px" />   
              <div className={goodsB.intro}>4、已发货页展示供应商用户已发货的记录</div>
              <img src={require('../../public/sell/21.png')}   alt="log-in" width="780px" /> 
          </div>
        </div>
      );
    }
  }
  export default GoodsB;