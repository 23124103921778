import React, { Component } from 'react';
import set from './PurchaseSet.module.css';

class PurchaseSet extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 采购流程 {'>'} 采购设置</div>
          <div>
            <div className={set.intro_1}>首次使用，建议先进行采购设置，后续采购询价无需再进行设置</div>
            <div className={set.intro}>一、交易条件设置</div>
              <img src={require('../../public/procurement/05.png')}   alt="log-in" width="780px" />
                <div className={set.titleAndDesc}><div className={set.title}>中标条件：</div><div className={set.desc}>用于查看询价单详情，询价明细页中比价的中标条件；</div></div>
                <div className={set.titleAndDesc}><div className={set.title}>付款方式：</div><div className={set.desc}>询价采购产品的付款方式；</div></div>
                <div className={set.titleAndDesc}><div className={set.title}>报价期限：</div><div className={set.desc}>指定报价供应商终止报价的时间点；</div></div>
                <div className={set.titleAndDesc}><div className={set.title}>接收报价：</div><div className={set.desc}>定时接收即截止报价期限时间点才接收报价单，实时接收即报价期限内报价发送变动将实时接收报价单；</div></div>
                <div className={set.titleAndDesc}><div className={set.title}>税率运费：</div><div className={set.desc}>询价产品的报价包含了税价和运费，并可设置税率要求；</div></div>
                <div className={set.titleAndDesc}><div className={set.title}>发票类型：</div><div className={set.desc}>要求供应商在采购产品交易中开具的发票类型，包含增值发票或普通发票。</div></div>
              <div className={set.intro}>二、交易地址设置</div>
                <img src={require('../../public/procurement/06.png')}   alt="log-in" width="780px" />
                <div className={set.titleAndDesc}><div className={set.title}>地址信息包括：</div><div className={set.desc}>姓名、手机号码、收货地址。</div></div>
                <div className={set.titleAndDesc}><div className={set.title}>指定收件方式：</div><div className={set.desc}>限定重量并指定物流快递。</div></div>
                <div className={set.titleAndDesc}><div className={set.title}>默认：</div><div className={set.desc}>可设置地址为默认收货地址或默认收票地址进行使用。</div></div>
          </div>
        </div>
      );
    }
  }
  export default PurchaseSet;