
import React, { Component } from 'react';
import begintooffer from './BeginToOffer.module.css';

class BeginToOffer extends Component {
    render() {
      return (
        <div>
          <div className="guide"> 使用指南 {'>'} 销售流程 {'>'} 开始报价</div>
         
          <div>
              <div className={begintooffer.intro_1}>简单两步，完成报价</div>
              <div className={begintooffer.intro}>一、 明细报价</div>
              <img src={require('../../public/sell/08.png')}   alt="log-in" width="780px" />
              <div className={begintooffer.intro}>二、整单报价(进入报价单详细页)</div>
              <img src={require('../../public/sell/09.png')}   alt="log-in" width="780px" />
          </div>
        </div>
      );
    }
  }
  export default BeginToOffer;